import React from "react"
import { graphql } from "gatsby"
import LayoutV2 from "../components/v2/layout-no-padding-site-main"
import SEO from "../components/seo"
import rehypeReact from "rehype-react"

import "../utils/css/components/insights.css"
import { PLink, BlogListItem, EmptyComponent, BlogList, Section } from "../components/partials/base";

import { IoIosBook } from "react-icons/io"

class BlogPostTemplate extends React.Component {
    constructor(props) {
      super(props)
      this.parChild = React.createRef()
      this.parChildDetails = React.createRef()
    }

    render() {
      const renderAst = new rehypeReact({
        createElement: React.createElement,
        components: {
            "title-section": TitleSection,
            "img" : ImageSection
        },
      }).Compiler
  
      const post = this.props.data.markdownRemark
      const siteTitle = this.props.data.site.siteMetadata.title

      return (
        <LayoutV2
          location={this.props.location}
          title={siteTitle}
          headerActive={`Insights`}
        >
          <SEO
            title={post.frontmatter.title}
            description={post.frontmatter.description || post.excerpt}
          />
          <div className="insight-blog-page">
            <TitleSection frontmatter={post.frontmatter}/>
            <article className="post-content page-template animated fadeIn">
                {renderAst(post.htmlAst)}
                <PrevNextDiv context={this.props.pageContext}/>
            </article>
          </div>
        </LayoutV2>
      )
    }
}

class PrevNextDiv extends React.Component {
    render () {
        const {previous, next} = this.props.context, showPrev = previous.frontmatter.posttype === "prosource-insight", showNext = next.frontmatter.posttype === "prosource-insight";
        let prevItem = previous.frontmatter; prevItem.link = previous.fields.slug; 
        let nextItem = next.frontmatter; nextItem.link = next.fields.slug;
    

        return <Section sectionName={`More from Distributed Insights`} sectionClassName={`section-div section-div-sm`}>
                <div className="insight-page-prev-next blog-list">
                {showPrev ? <BlogListItem item={prevItem}/> : <EmptyComponent/>}
                {showNext ? <BlogListItem item={nextItem}/> : <EmptyComponent/>}
            </div>
        </Section>
    }
}

class ImageSection extends React.Component {
    render () {
        const { src } = this.props; 
        return <p style={{textAlign: 'center'}}><img src={src} className="insight-img"/></p>
    }
}

class TitleSection extends React.Component {
    render() {  
        const {title, date, author, minutes} = this.props.frontmatter;
        return (
            <div className="insight-title-div">
                <div className="insight-title-div--title">
                    {title}
                </div>
                <div className="insight-title-div--author-and-date">
                    <span className="insight-title-div--author-span">
                        By {author}, 
                    </span>
                    <span className="insight-title-div--date-span">
                        {date}
                    </span>
                    <span style={{display: `block`}}>
                        <IoIosBook/> {minutes} min read
                    </span>
                </div>
            </div>
        )
    }
}  
export default BlogPostTemplate

export const pageQuery = graphql`
  query InsightBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      htmlAst
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        bgcover
        author
        minutes
      }
    }
  }
`
